import { ResponsiveImage } from '../ResponsiveImage/ResponsiveImage';
import { ResponsiveImageWrapperProps } from './ResponsiveImageWrapper.types';

export const ResponsiveImageWrapper = ({
  src,
  layout,
  alternativeText,
  width,
  height,
  isNoscript = false,
  ...rest
}: ResponsiveImageWrapperProps) => {
  return !isNoscript ? (
    <ResponsiveImage
      src={src}
      width={width}
      height={height}
      layout={layout}
      alternativeText={alternativeText}
      {...rest}
    />
  ) : (
    <img
      alt={alternativeText}
      src={src}
      decoding="async"
      srcSet={`${src} 1x, ${src} 2x`}
    ></img>
  );
};
