import classNames from 'classnames';
import { isValidElement, useMemo, useState } from 'react';
import YouTube from 'react-youtube';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import { ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { ResponsiveImageWrapper } from 'ui/components/ResponsiveImageWrapper';
import ReactPlayer from 'react-player/vimeo';

import styles from './videoInternal.module.scss';

const defaultPlayerOptions = {
  autoplay: 1,
  controls: 0,
  playsinline: 1,
  modestbranding: 1,
  start: 0,
  mute: 1,
  rel: 0,
  fs: 1,
};

/**
 * @type {import('react').FC<{ data: { thumb: { time: string; img: { src: string; alt: string; } | React.ReactElement } | React.ReactElement; video: { id: string; videoURL?: string; videoProvider?: 'YOUTUBE' | 'VIMEO' } }; className?: string; playIcon?: string; }>}
 */
export const VideoInternal = props => {
  const { className, data, isNoscript = false, playIcon } = props;
  const { thumb, video } = data || {};

  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [isBeingHovered, setIsBeingHovered] = useState(false);
  const [playerOptions, setPlayerOptions] = useState(defaultPlayerOptions);
  const [windowWidth] = useWindowsSize();

  const events = useMemo(() => {
    const handleClick = () => {
      setIsVideoPlayed(state => !state);
      setPlayerOptions({
        ...playerOptions,
        controls: 1,
        mute: 0,
      });
    };

    const clickEvents = {
      onClick: handleClick,
      onKeyDown: handleClick,
    };

    if (windowWidth <= ResponsiveBreakpoint) {
      return clickEvents;
    }

    return {
      ...clickEvents,
      onMouseEnter: () => setIsBeingHovered(true),
      onMouseLeave: () => setIsBeingHovered(false),
    };
  }, [playerOptions, windowWidth]);

  const showVideoPlayer = isBeingHovered || isVideoPlayed; // only render the video player when the user either hovers on the section or clicks on the play button
const getVideoPlayer = () => {
  switch(video.videoProvider) {
    case 'YOUTUBE': return <YouTube videoId={video.id} opts={{ playerVars: playerOptions }} data-elastic-name="video" data-testid="video" />;
    case 'VIMEO': return <ReactPlayer url={video.videoURL} muted={!isVideoPlayed} playing={showVideoPlayer} controls data-elastic-name="video" data-testid="video" />;
    // Adding default as youtube, because it might cause issues for existing docs
    default: return <YouTube videoId={video.id} opts={{ playerVars: playerOptions }} data-elastic-name="video" data-testid="video" />;
  }
}
  return !video ? null : (
    <div className={classNames(styles['video-wrap'], className)}>
      <div
        className={classNames(styles['video-thumb'], {
          [styles['video-thumb-hide']]: isVideoPlayed,
        })}
        role="presentation"
        data-testid="videoContainer"
        {...events}
      >
        {showVideoPlayer && getVideoPlayer()}
        {!showVideoPlayer &&
          (isValidElement(thumb)
            ? thumb
            : thumb?.img && (
                <ResponsiveImageWrapper
                  src={thumb.img.src}
                  alternativeText={thumb.img.alt}
                  data-testid="thumbnail"
                  layout="fill"
                  isNoscript={isNoscript}
                  {...thumb.img}
                />
              ))}
        {!isVideoPlayed && thumb?.img && thumb?.time && (
          <p className={styles['video-thumb-time']} data-testid="time">
            {thumb.time}
          </p>
        )}
        {!isVideoPlayed && ( 
          <img
            src={playIcon || "/img/icons/icon-play-video.svg"}
            className={styles['video-thumb-play-btn']}
            alt="icon"
            loading="lazy"
            data-testid="playIcon"
          />
        )}
      </div>
    </div>
  );
};
