import { Section } from 'ui/sections/section';
import { VideoInternal } from 'ui/components/VideoInternal';

export default function VideoInternalSection({ sectionProps = {}, ...rest }) {
  return !rest.video ? null : (
    <Section {...sectionProps}>
      <VideoInternal data={rest} />
    </Section>
  );
}
