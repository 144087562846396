import { Content, asLink } from '@prismicio/client';
import { PrismicNextImage } from '@prismicio/next';
import { SliceComponentProps } from '@prismicio/react';
import { PrismicRichText } from 'ui/components/PrismicRichText';
import { ResponsiveBreakpoint } from 'ui/components/ResponsiveImage';
import { extractVideoId } from 'ui/components/developer/Video/video.helper';
import { useWindowsSize } from 'ui/hooks/useWindowSize';
import VideoInternalSection from 'ui/sections/VideoInternalSection';
import { VideoInternal } from 'ui/components/VideoInternal';
import classNames from 'classnames';
import { asImageSrc } from '@prismicio/client';
import { SVGLibrary } from 'ui/svgs/library';
import { Button } from 'ui/components/Button';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
/**
 * Props for `VideoBlock`.
 */
export type VideoBlockProps = SliceComponentProps<Content.VideoBlockSlice>;

/**
 * Component for "VideoBlock" Slices.
 */
const VideoBlock = ({ slice }: VideoBlockProps): JSX.Element => {
  const { primary, slice_type, variation } = slice;
  const { thumbnail, video_url } = primary;
  const [width] = useWindowsSize();
  const [bg, setBg] = useState();
  useEffect(() => {
    window.addEventListener('backgroundColorChange', (event: any) => {
      setBg(event.detail.color);
    });
    () => {
      window.removeEventListener('backgroundColorChange', () => {});
    };
  }, []);
  useEffect(() => {
    function handleScroll() {
      const element = document.querySelector(`.${styles.video}`) as HTMLDivElement;
      if (element) {
        const elementTop = element.getBoundingClientRect().top;
        const windowHeight = window.innerHeight;

        if (elementTop < windowHeight && elementTop > 0) {
          // Calculate the new width based on scroll position as a percentage
          const maxWidthPercent = 100; // Max width as a percentage
          const minWidthPercent = 82.32; // Min width as a percentage (calculated from 1080 / 1312 * 100)
          const scrollRange = windowHeight; // The range within the viewport
          const scrolled = Math.min(windowHeight - elementTop, scrollRange); // How much the element has been scrolled into view
          const newWidthPercent = minWidthPercent + (maxWidthPercent - minWidthPercent) * (scrolled / scrollRange);

          element.style.width = `${Math.min(newWidthPercent + 5, 100)}%`;
        } else if (elementTop >= windowHeight) {
          // Reset width if the element is above the viewport
          element.style.width = '82.32%';
        }
      }
    }

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', () => {});
    };
  }, []);
  if (variation === 'blockWithText') {
    const { cta_url, cta_text, cta_icon, video_provider } = primary;
    return (
      <section
        className={classNames(
          styles.root,
          'wide-container',
          styles[bg || primary.background],
          'color-changing-section',
        )}
        data-slice-type={slice_type}
        data-slice-variation={variation}
        data-background={primary.background}
      >
        <div className={styles.content}>
          <div className={classNames(styles.header, styles[primary.layout])}>
            <div className={styles.heading} data-testid="heading">
              {primary.header}
            </div>
            <div>
              <PrismicNextImage field={primary.text_image} className={styles.text_image} />
              <PrismicRichText field={primary.text} className={styles.text} />
              {cta_text && (
                <div className={styles.cta}>
                  <Button className={classNames(styles.action)} baseBtn={'primary'} field={cta_url} dataTestid="cta">
                    <SVGLibrary name={cta_icon} fill="white" />
                    {cta_text}
                  </Button>
                </div>
              )}
            </div>
          </div>
          {primary.media_type === 'Image' ? (
            <>
              {asImageSrc(primary.image) ? (
                <div className={styles['video-thumb']}>
                  <PrismicNextImage field={primary.image} />
                </div>
              ) : (
                <SVGLibrary name={(primary.svg_inline as any) || 'Rowing'} />
              )}
            </>
          ) : (
            <VideoInternal
              data={{
                video: {
                  id: extractVideoId(primary.youtube_video_id),
                  videoProvider: video_provider,
                  videoURL: asLink(video_url),
                },
                thumb: <PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} />,
              }}
              playIcon={'/img/icons/icon-play-button.svg'}
              className={styles.video}
            />
          )}
        </div>
      </section>
    );
  }
  return (
    <VideoInternalSection
      data-slice-type={slice_type}
      data-slice-variation={variation}
      video={{ id: extractVideoId(asLink(video_url)) }}
      thumb={<PrismicNextImage field={thumbnail} fill style={{ objectFit: 'cover' }} />}
      sectionProps={{
        pad: width > ResponsiveBreakpoint ? 'none' : 'sm',
      }}
    />
  );
};

export default VideoBlock;
